import React from "react";
import "./Ios.css";
import IOS_application from "../Services/img/ios1.png";
import IOS_app_development from "../Services/img/ios2.jpg";
import Mobile_application from "../Services/img/Mobile_application.webp";
const IOSPage = () => {
  return (
    <>
      {/* <div className="carrear_image">
                <div className="carrear_img">
                    <img src={IOS_application} width="100%" alt="IOS Application" className="sli_img1" />
                </div>
            </div> */}

      <div>
        <div>
          <img
            src={IOS_application}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <h1 className="product_title">IOS App Development</h1>
            <p class="cont_para_style">
              IOS Application Development is growing rapidly in the market,
              allowing for easy interaction and instant effective communication.
              The recent trend of integrated app stores within smartphones is
              crucial. A mobile application is the most effective way to engage
              your target audience on a more personal level. A mobile
              application is also far more conversion-friendly than a website
              because installation indicates that a user is interested in your
              company. That is why user is downloading and installing your
              application.
            </p>
            <div className="img_pro">
              <img
                src={IOS_app_development}
                width="100%"
                height="100%"
                alt="App Development"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              Our expert designers and developers will collaborate with you to
              ensure that your iOS application is designed and implemented based
              on your business requirements and has a UI that is competitive
              with the best apps on the market.{" "}
            </p>
            <p className="cont_para_style">
              {" "}
              We have extensive experience creating business-centric,
              goal-focused, and performance-based apps for your future company
              using our mobile app developers. We only hire qualified mobile app
              developers who have custom hand-code development experience, as
              well as strong creativity and analytical abilities. We have
              developed more mobile apps for major platforms such as Android,
              iOS, Windows, and cross-browser phone apps over the years.{" "}
            </p>
            <p className="cont_para_style">
              Are you looking for IOS Application Development Service? Feel free
              to contact us<a href="contact.html"> @ 7010816299</a>
            </p>
            <div className="img_pro">
              <img
                src={Mobile_application}
                width="100%"
                height="100%"
                alt="Mobile Application"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              Our Kitkat software technologies app developers in Coimbatore have
              been critical to our product's success. They have worked
              incredibly hard to create a high-quality product that meets our
              users' needs while remaining user-friendly. They used their
              knowledge and experience to create a mobile app that is safe,
              reliable, and simple to use. They have done everything with high
              accuracy and dedication, from coding and debugging to testing and
              deployment.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default IOSPage;
