import React from "react";
import "./Biometric.css";
import Hospital_management from "../Products/img/Hospital_management.webp";
import medical_billing from "../Products/img/medical_billing.webp";
import healthcare_industry from "../Products/img/healthcare_industry.webp";

const MedicalPage = () => {
  return (
    <>
      {/* <div className="carrear_image">
                <div className="carrear_img">
                    <img src={Hospital_management} width="100%" height="100%" alt="Hospital management" className="sli_img1" />
                </div>
            </div> */}

      <div>
        <div>
          <img
            src={Hospital_management}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <h1 className="product_title">Medical Billing</h1>
            <p className="cont_para_style">
              Medical billing software automates the healthcare billing process
              for doctors and medical offices. Finding the best computer
              application for your needs will increase your office's reimbursed
              rates, revenue, and help you build a long-term medical practice.
            </p>
            <p className="cont_para_style">
              Kitkat Software Technologies' medical billing and coding software
              enables the quick and easy receipt of payments and the processing
              of financial transactions. We develop medical software for
              hospitals, private doctor's practices, pharmacies, and medical
              device and supply companies
            </p>
            <div className="side_img">
              <img
                src={medical_billing}
                width="100%"
                height="100%"
                alt="Medical billing"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              An automated medical billing system makes it simple to manage
              medical bills and avoid errors. Furthermore, the software will aid
              in the acceleration of most billing procedures, easing the burden
              on the medical billing unit. The majority of medical institutions
              use medical billing systems, with many claiming lower error rates
              and improved medical income responsibility. Understanding and
              developing medical billing software is thus the first step towards
              preventing your medical staff from becoming overwhelmed with paper
              bills.
            </p>
            <p className="cont_para_style">
              Our medical billing software development firm provides a
              cross-functional team of senior IT professionals capable of
              payment-processing system engineering services such as front-end,
              back-end, and mobile development, remote tech consulting, user
              interface design, and quality assurance services for custom
              medical billing systems.s.
            </p>
            {/* <h2 class="title2">Booking Appointments :</h2> */}
            <ul style={{ fontSize: "18px", lineHeight: "24px" }}>
              <li>
                <b>Booking Appointments : </b>While online appointment
                scheduling is not something to look for in standard medical
                billing software, having it in your billing system can be
                beneficial.
              </li>
              <li>
                <b>Coding Automation: </b> Handling treatment plans is far too
                detailed and complex. Medical billing and coding software
                automates the coding of treatment details for patients in the
                system.
              </li>
              <li>
                <b>Reporting: </b>Based on data analysis, custom medical billing
                platforms generate a variety of reports, including claims
                history and current status.
              </li>
              <li>
                <b>Integration of Payment Methods: </b>Medical billing software
                requires credit card processing. Aside from that, the system
                offers a number of other payment options.
              </li>
              <li>
                <b>An easy-to-use interface: </b> We create user-friendly
                solutions while also developing high-quality core features. Our
                clients benefit from an easy-to-use interface and best practises
                in user experience.
              </li>
              <li>
                <b>Pre-Registration of Patients: </b>Patients can use the
                medical billing software to pre-register for services, which is
                linked to their system accounts.
              </li>
            </ul>
            <div className="side_img">
              <img
                src={healthcare_industry}
                width="100%"
                height="100%"
                alt="Management software"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicalPage;
