import slide1 from "../Sliderimg/slide01.jpg";
import slide2 from "../Sliderimg/slide02.webp";
import slide3 from "../Sliderimg/slide03.webp";
import slide4 from "../Sliderimg/slide04.webp";
import slide5 from "../Sliderimg/slide05.jpg";

const SliderItems = [
  {
    id: 1,
    title: "Making Web Dreams a Reality, Every Day.",
    image: slide1,
  },
  {
    id: 2,
    title: "We Create, You Conquer",
    image: slide2,
  },
  {
    id: 3,
    title: "Connecting You Everywhere",
    image: slide3,
  },
  {
    id: 4,
    title: "Teams make dreams and more",
    image: slide4,
  },
  { id: 5, title: "A fresh new look for your site", image: slide5 },
];

export default SliderItems;
