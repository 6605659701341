import React from "react";
import "./Gold.css";
import Goldloan from "../Products/img/gold1.jpg";
import Goldloan_management from "../Products/img/Goldloan_management.webp";
import Goldloan_software from "../Products/img/gold2.png";
const GoldPage = () => {
  return (
    <>
      {/* <div className="carrear_image">
                <div className="carrear_img">
                    <img src={Goldloan} width="100%" height="100%" alt="Goldloan" className="sli_img1" />
                </div>
            </div> */}

      <div>
        <div>
          <img
            src={Goldloan}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <h1 className="product_title">Gold Loan Management</h1>
            <p className="cont_para_style">
              {" "}
              Kitkat Software Technologies Gold Loan Software simplifies gold
              loan accounting and interest rate calculation. By providing party
              information, interest calculations, and loan reminders, the gold
              loan software aids in the management of secured and unsecured
              loans. The gold loan software includes a number of reports that
              must be submitted in order for the gold loan license to be
              renewed. This gold loan software is used by many Pawnbrokers to
              manage their gold loan accounting and calculation. The computer
              code is extremely simple to use and offers a plethora of useful
              options for tracking gold, payment pursuit, loan proceeding, loan
              installment purchasing, and so on. This software also generates
              various types of reports that are useful for a gold loan. It's
              simple to use software with a wide range of functionality,
              messaging (sms alerts), and reporting options.
            </p>
            <div className="side_img">
              <img
                src={Goldloan_management}
                width="100%"
                height="100%"
                alt="Goldloan Software"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              The Gold Loan Software understands both the technological
              requirements of a complex loan product like gold loans and the
              requirements of the jewel finance sector. Our web Solutions Gold
              Loan software is a sophisticated method of managing customer
              records and streamlining loan processes in order to reduce
              turnaround time and total cost, reduce risk, and increase company
              profitability.
            </p>
            <div className="side_img">
              <img
                src={Goldloan_software}
                width="100%"
                height="100%"
                alt="Goldloan Management"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              Kitkat Software Technologies Gold Loan software makes it simple to
              manage all of the complexities of the gold loan financing business
              by recording business statistics, performing maintenance and
              accounting tasks, and more. If you decide to purchase software,
              please contact us for a gold loan software demo, gold loan
              software pricing information, and instructions on how to obtain
              gold loan software free download completely.
            </p>
            <p className="cont_para_style">
              The software has been specially designed and developed to automate
              the entire loan processing and premium tracking. Kitkat
              Technologies web solutions are the best, most affordable, and most
              widely used. Gold Loan Software has increased operational
              efficiency by optimizing the business process. The loan amount is
              determined by the applicant's income and ability to repay. Loan
              origination software is a group of cutting-edge tools that improve
              the agility, speed, and transparency of a company's overall
              lending solutions for customers. It enables financial institutions
              and banks to fully automate gold loan management processes,
              resulting in significant cost savings and improved customer
              satisfaction.
            </p>
            <p className="cont_para_style">
              Gold Loan Management Software, we have accumulated vast knowledge
              and experience that has assisted us in designing and developing
              one of the best Gold Loan Management software in the market.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldPage;
